








































































import { PageBase } from "@/core/models/shared";
import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import { ContratoService } from "../core/services/Clientes"
import { AssinanteService } from "@/core/services/Base";

@Component
export default class Home extends PageBase {
  loading: boolean = false;
  dialogCadastro: boolean = false;
 // lista: any[] = [];
  item: any = {};
  service = new ContratoService();

  contratoAberto: number = 0;
  dialogMeusDados:boolean = false;

  dialogListaContratos:boolean = false;
  dialogExtrato:boolean = false;

  /*Carregar(){
    this.loading = true;
    this.service.ListarPorCliente(this.app.clienteId).then(
      res => {
        this.lista = res.data;
      },
      err => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else {
          this.$swal("Aviso", err.response.data, "error");
        }
      }
    ).finally(() => {
      this.loading = false;
    })
  }*/

  mounted(){
   // this.Carregar();
  }
 
  AbrirWhatsApp(){
    new AssinanteService().WhatsApp(this.app.assinanteId).then(
      res => {
        let numero = res.data;
        console.log(numero)
        numero = numero.replaceAll("(", "");
        numero = numero.replaceAll(")", "");
        numero = numero.replaceAll("-", "");
        numero = numero.replaceAll(" ", "");
        numero = numero.trim();

        window.open(`https://api.whatsapp.com/send?phone=55${numero}`);
      },
      err =>{
        this.$swal('Aviso', err.message, 'error')
      })
  }

  headers = [
    { text: 'Contrato',  value: 'numero', align: "center", sortable: false},
    { text: 'Loteamento', value: 'empreendimento.nome', align: "center" , sortable: false},
    { text: 'Quadra', value: 'unidade.grupo.nome', align: "center" , sortable: false},
    { text: 'Lote', value: 'unidade.nome', align: "center", sortable: false },
  ]
}
