var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('master-page',[_c('v-card',[_c('v-card-title',[_c('v-icon',{attrs:{"large":"","left":"","color":"primary"}},[_vm._v("mdi-home-circle")]),_c('span',{staticClass:"title font-weight-medium"},[_vm._v("Meu espaço")]),_c('v-spacer')],1),_c('div',[_c('v-row',{staticClass:"ma-6"},[_c('v-hover',{attrs:{"close-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"ma-1",attrs:{"height":"150","width":"160","elevation":hover ? 24 : 2},on:{"click":function($event){_vm.dialogListaContratos = true}}},[_c('div',{staticClass:"text-center py-6"},[_c('v-img',{staticClass:"center",attrs:{"height":"70","width":"90","src":require("../assets/images/home.png")}}),_c('p',{staticClass:"py-2 font-padrao"},[_vm._v("Meus Contratos")])],1)])]}}])}),_c('v-hover',{attrs:{"close-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"ma-1",attrs:{"height":"150","width":"160","elevation":hover ? 24 : 2,"link":""},on:{"click":function($event){_vm.dialogExtrato = true}}},[_c('div',{staticClass:"text-center py-6"},[_c('v-img',{staticClass:"center",attrs:{"height":"70","width":"70","src":require("../assets/images/extrato.png")}}),_c('p',{staticClass:"py-2 font-padrao"},[_vm._v("Extrato de pag.")])],1)])]}}])}),_c('v-hover',{attrs:{"close-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"ma-1",attrs:{"height":"150","width":"160","elevation":hover ? 24 : 2,"link":""},on:{"click":function($event){_vm.dialogMeusDados = true}}},[_c('div',{staticClass:"text-center py-6"},[_c('v-img',{staticClass:"center",attrs:{"height":"70","width":"70","src":require("../assets/images/user.png")}}),_c('p',{staticClass:"py-2 font-padrao"},[_vm._v("Meus dados")])],1)])]}}])}),_c('v-hover',{attrs:{"close-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"ma-1",attrs:{"link":"","height":"150","width":"160","elevation":hover ? 24 : 2},on:{"click":function($event){return _vm.AbrirWhatsApp()}}},[_c('div',{staticClass:"text-center py-6"},[_c('v-img',{staticClass:"center",attrs:{"height":"70","width":"70","src":require("../assets/images/ajuda.png")}}),_c('p',{staticClass:"py-2 font-padrao"},[_vm._v("Ajuda")])],1)])]}}])})],1)],1),_vm._v(" "),_c('br'),_c('br')],1),_c('meus-dados',{on:{"fechou":function($event){_vm.dialogMeusDados = false}},model:{value:(_vm.dialogMeusDados),callback:function ($$v) {_vm.dialogMeusDados=$$v},expression:"dialogMeusDados"}}),_c('lista-contratos',{on:{"fechou":function($event){_vm.dialogListaContratos = false}},model:{value:(_vm.dialogListaContratos),callback:function ($$v) {_vm.dialogListaContratos=$$v},expression:"dialogListaContratos"}}),_c('extrato-pagamento',{on:{"fechou":function($event){_vm.dialogExtrato = false}},model:{value:(_vm.dialogExtrato),callback:function ($$v) {_vm.dialogExtrato=$$v},expression:"dialogExtrato"}}),_c('v-overlay',{attrs:{"value":_vm.loading}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }